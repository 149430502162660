<template>
  <div class="home_page h-full">
    <theHeader class="header-shadow" />

    <div class="md:pt-20 pt-16">
      <router-view :retour="position" :suivant="suivant" @steep="retourSteep"/>
    </div>
  </div>
</template>

<script>
import theHeader from '@/components/helper/header'

export default {
  name: 'Home',
  components: {
    theHeader
  },
  data () {
    return {
      position: 0,
      suivant: 0,
      steep: 'continuer'
    }
  },
  computed: {
    donnee: function () {
      return this.position
    }
  },
  methods: {
    retourSteep (answer) {
      this.steep = answer
    },
    retourInfo (answer) {
      this.position = this.position + 1
    },
    retourNext (answer) {
      this.suivant = this.suivant + 1
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/style/sass/variables";
.home_page {
  width: 1440px;
  margin-left: auto;
  margin-right: auto;
  background-color: #F5F5F5;
}

@media screen and (max-width: 1440px) {
  .home_page {
    background-color: #F5F5F5;
    width: 100%;
    height: 100%;
  }
  .home_page::-webkit-scrollbar {
    display: none;
  }
  .header-shadow {
    position: fixed;
  }
  .footers {
    position: relative;
  }
}
</style>
